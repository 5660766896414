import React from "react"
import { graphql } from "gatsby"

import Layout from "../layouts/Layout/Layout"
import Carousel from "../components/Carousel/Carousel"

export default function NotFoundPage({ data }) {
  return (
    <Layout>
      <Carousel
        items={(data.allDatoCmsProject.edges || []).map(o => o.node.cover)}
      />
    </Layout>
  )
}

export const query = graphql`
  query {
    allDatoCmsProject(
      filter: { tags: { elemMatch: { slug: { eq: "selecionados" } } } }
    ) {
      edges {
        node {
          code
          url
          cover {
            fluid(
              maxWidth: 960
              imgixParams: { fm: "webp", auto: "compress" }
            ) {
              ...GatsbyDatoCmsFluid
            }
          }
        }
      }
    }
  }
`
